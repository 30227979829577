<template>
  <v-row class="users-list--all">
    <v-col cols="12">
      <div class="form-container d-flex align-center">
        <div class="search-form">
          <v-text-field
            flat
            hide-details
            solo
            rounded
            clearable
            label="Search name or email"
            v-model="searchKey"
            @input="searchChange"
            style="width: 400px"
          />
        </div>
      </div>
    </v-col>

    <v-col cols="12 px-4">
      <users-list-table
        :users="users"
        :meta="listMeta"
        :links="listLinks"
        :loading="loading"
        @loadMore="loadMore"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import UsersListTable from '../components/UsersListTable'
import debounce from 'lodash/debounce'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiDownload,
} from '@mdi/js'

export default {
  name: 'PendingUsersList',

  components: {
    UsersListTable,
  },

  data() {
    return {
      searchKey: '',
      loading: false,
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiDownload,
      },
    }
  },

  created() {
    this.searchKey = this.filter.search

    this.clearUsers()

    this.setFilter({
      ...this.filter,
      type: 'pending',
    })

    this.fetchUsers()
  },

  computed: {
    ...mapState({
      users: (state) => state.users.list,
      listMeta: (state) => state.users.listMeta,
      listLinks: (state) => state.users.listLinks,
      filter: (state) => state.users.filter,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  methods: {
    ...mapActions({
      getUsers: 'users/getUsers',
    }),

    ...mapMutations({
      clearUsers: 'users/clearUserList',
      setFilter: 'users/setFilter',
    }),

    loadMore() {
      if (this.listLinks.next) {
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },

    searchChange: debounce(function() {
      this.setFilter({
        ...this.filter,
        search: this.searchKey,
        type: 'pending',
      })

      this.clearUsers()
      this.fetchUsers()
    }, 600),

    async fetchUsers(page = 1) {
      if (this.loading) return
      let params = { page }
      if (this.searchKey) {
        params.search = this.searchKey
      }
      this.loading = true
      await this.getUsers(params)
      this.loading = false
    },
  },
}
</script>
